













import { Component, Prop } from 'vue-property-decorator'

import { AbstractForm } from '../Form.abstract.vue'

/**
 * AbstractForm component
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Form>({ name: 'AbstractForm' })
export class Form extends AbstractForm {
  @Prop({ type: Boolean, required: false, default: () => false })
  public isDisabledButton!: boolean

  /**
   * Promise function to fire on form.submit.
   */
  @Prop({ type: String, required: false })
  public submitLabel?: string

  @Prop({ type: Boolean, required: false, default: true })
  public showButton?: boolean

  /**
   * Determines whether submit label is present.
   */
  public get hasLabel (): boolean {
    return typeof this.submitLabel !== 'undefined' &&
      this.submitLabel.replace(' ', '').length > 0
  }
}

export default Form
